/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConfigurationApi,
  UserConfigurationDtoGrammarInferenceModelEnum,
  UserConfigurationDtoLanguageCheckModeEnum,
  UserConfigurationDtoEnglishLanguageTypeEnum,
  UserConfigurationDto,
  UserConfigurationDtoGlossaryListEnum,
  RuleSetInfoDto,
} from '@ink-ai/insight-service-sdk';
import { RootState } from '.';
import { getApi } from '../common/requestHelper';
import { ToolType } from '@ink-ai/dbrr-toolkit';
import { equals, not, remove } from 'ramda';
import { correction } from './correction';
import { writingStyle } from './writing-style';
import { sentenceBreak } from './common';

export interface ConfigurationState {
  loading: boolean;
  isConfigModified: boolean;
  glossaryList: UserConfigurationDtoGlossaryListEnum[];
  customGlossaryList: string[];
  docBarRaiserReviewers: ToolType[];
  grammarInferenceModel: UserConfigurationDtoGrammarInferenceModelEnum;
  languageCheckMode: UserConfigurationDtoLanguageCheckModeEnum;
  englishLanguageType: UserConfigurationDtoEnglishLanguageTypeEnum;
  tourEnabled: boolean;
  writingStyle: RuleSetInfoDto[];
}

const initialState: ConfigurationState = {
  loading: false,
  isConfigModified: false,
  glossaryList: [],
  customGlossaryList: [],
  docBarRaiserReviewers: [],
  grammarInferenceModel: UserConfigurationDtoGrammarInferenceModelEnum.Claude,
  languageCheckMode: UserConfigurationDtoLanguageCheckModeEnum.FullPaper,
  englishLanguageType: UserConfigurationDtoEnglishLanguageTypeEnum.America,
  tourEnabled: false,
  writingStyle: [],
};

export const isGrammarConfigChanged = (
  originState: UserConfigurationDto,
  newState: UserConfigurationDto,
) =>
  not(
    equals(originState.grammarInferenceModel, newState.grammarInferenceModel),
  ) ||
  not(equals(originState.languageCheckMode, newState.languageCheckMode)) ||
  not(equals(originState.glossaryList, newState.glossaryList));

export const isWritingStyleConfigChanged = (
  originState: UserConfigurationDto,
  newState: UserConfigurationDto,
) =>
  not(equals(originState.writingStyle, newState.writingStyle)) ||
  not(equals(originState.languageCheckMode, newState.languageCheckMode));

export const upsertUserConfiguration = createAsyncThunk(
  'configuration/upsertUserConfiguration',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const configurationApi = await getApi(ConfigurationApi);

    const response = await configurationApi.saveConfiguration({
      glossaryList: state.configuration.glossaryList,
      customGlossaryList: state.configuration.customGlossaryList,
      docBarRaiserReviewers: state.configuration.docBarRaiserReviewers,
      grammarInferenceModel: state.configuration.grammarInferenceModel,
      languageCheckMode: state.configuration.languageCheckMode,
      englishLanguageType: state.configuration.englishLanguageType,
      tourEnabled: state.configuration.tourEnabled,
      writingStyle: state.configuration.writingStyle,
    });

    return response;
  },
);

export const userConfigurationChanged = createAsyncThunk(
  'configuration/userConfigurationChanged',
  async (payload: UserConfigurationDto, { getState, dispatch }) => {
    const state = getState() as RootState;
    // reset grammar correction if grammar configuration changed
    if (isGrammarConfigChanged(state.configuration, payload)) {
      dispatch(correction.actions.replaceGrammarList([]));
    }
    // reset writing style correction if writing style or language check mode configuration changed
    if (isWritingStyleConfigChanged(state.configuration, payload)) {
      dispatch(writingStyle.actions.replaceWritingStyleList([]));
    }
    // detect language check mode change
    if (
      not(
        equals(
          state.configuration.languageCheckMode,
          payload.languageCheckMode,
        ),
      )
    ) {
      if (
        payload.languageCheckMode ===
        UserConfigurationDtoLanguageCheckModeEnum.Selection
      ) {
        // pause scanning if grammar check mode is switched to Selection
        dispatch(correction.actions.setScanning(false));
      } else {
        // split full doc to text list if switched to full doc
        dispatch(sentenceBreak(state.common.doc));
      }
    }
    dispatch(configuration.actions.updateUserConfiguration(payload));
  },
);

export const getUserConfiguration = createAsyncThunk(
  'configuration/getUserConfiguration',
  async (_, { dispatch }) => {
    const configurationApi = await getApi(ConfigurationApi);
    const response = await configurationApi.getConfiguration();
    console.log('response.data', JSON.stringify(response.data, null, 2));
    dispatch(configuration.actions.updateUserConfiguration(response.data));

    return response.data;
  },
);

export const configuration = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    updateUserConfiguration(
      state,
      { payload }: PayloadAction<UserConfigurationDto>,
    ) {
      state.glossaryList = payload.glossaryList;
      state.docBarRaiserReviewers = payload.docBarRaiserReviewers.map(
        (item: string) => item as ToolType,
      );
      state.grammarInferenceModel = payload.grammarInferenceModel;
      state.languageCheckMode = payload.languageCheckMode;
      state.englishLanguageType = payload.englishLanguageType;
      state.tourEnabled = payload.tourEnabled;
      state.writingStyle = payload.writingStyle;
    },
    addDocBarRaiserReviewer: (state, action: PayloadAction<ToolType>) => {
      const toolType = action.payload;
      if (!state.docBarRaiserReviewers.includes(toolType)) {
        state.docBarRaiserReviewers.push(toolType);
        state.isConfigModified = true;
      }
    },
    removeDocBarRaiserReviewer: (state, action: PayloadAction<ToolType>) => {
      const toolType = action.payload;
      state.docBarRaiserReviewers = state.docBarRaiserReviewers.filter(
        (t) => t !== toolType,
      );
      state.isConfigModified = true;
    },
    addGlossary: (
      state,
      action: PayloadAction<UserConfigurationDtoGlossaryListEnum>,
    ) => {
      const glossary = action.payload;
      if (!state.glossaryList.includes(glossary)) {
        state.glossaryList.push(glossary);
        state.isConfigModified = true;
      }
    },
    removeGlossary: (
      state,
      action: PayloadAction<UserConfigurationDtoGlossaryListEnum>,
    ) => {
      const glossary = action.payload;
      state.glossaryList = remove(
        state.glossaryList.indexOf(glossary),
        1,
        state.glossaryList,
      );
      state.isConfigModified = true;
    },
    setGrammarInferenceModel: (
      state,
      action: PayloadAction<UserConfigurationDtoGrammarInferenceModelEnum>,
    ) => {
      state.grammarInferenceModel = action.payload;
      state.isConfigModified = true;
    },
    updateRuleEnabledStatus: (
      state,
      action: PayloadAction<{
        ruleSetId: string;
        ruleId: string;
        isEnabled: boolean;
      }>,
    ) => {
      const { ruleSetId, ruleId, isEnabled } = action.payload;
      const ruleSetIndex = state.writingStyle.findIndex(
        (rs) => rs.id === ruleSetId,
      );
      if (ruleSetIndex > -1) {
        const ruleIndex = state.writingStyle[ruleSetIndex].rules.findIndex(
          (r) => r.id === ruleId,
        );
        if (ruleIndex > -1) {
          state.writingStyle[ruleSetIndex].rules[ruleIndex].enabled = isEnabled;
          state.isConfigModified = true;
        }
      }
    },
    setLanguageCheckMode: (
      state,
      action: PayloadAction<UserConfigurationDtoLanguageCheckModeEnum>,
    ) => {
      state.languageCheckMode = action.payload;
      state.isConfigModified = true;
    },
    setTourEnabled: (state, action: PayloadAction<boolean>) => {
      state.tourEnabled = action.payload;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    clearAll: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserConfiguration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserConfiguration.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserConfiguration.rejected, (state, action) => {
      console.error(action.error);
      state.loading = false;
    });
    builder.addCase(upsertUserConfiguration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertUserConfiguration.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertUserConfiguration.rejected, (state, action) => {
      console.error(action.error);
      state.loading = false;
    });
  },
});
